import React, { Component } from "react"
import { Link } from "gatsby";
import { FormattedMessage } from "gatsby-plugin-intl"

class Cookies extends Component {
  setCookieInfo () {
    if (typeof localStorage !== 'undefined') {
      document.getElementById('cookie-info').remove();
      localStorage.setItem('cookie_info', 1);
    }
  }

  componentDidMount () {
    if (typeof localStorage !== 'undefined') {
      if (!localStorage.getItem('cookie_info')) {
        document.getElementById('cookie-info').classList.remove('is-hidden');
        document.getElementById('cookie-info').classList.add('is-visible');
      }
    }
  }

  render () {    
    return (
      <div id="cookie-info" className="cookies is-hidden">
        <p>Utilizamos cookies para otimizar a sua experiência. Ao navegar em nosso site, você autoriza o uso dos cookies. Consulte nossa <Link to="/privacy-policy">Política de Privacidade</Link> para saber mais.</p>
        <button className="cookies__button button is-link" onClick={this.setCookieInfo} onKeyDown={this.setCookieInfo}>
          OK
        </button>
      </div>
    )
  }
};

export default Cookies
